<template lang="pug">
.free-page
  img.intro-img(src="../assets/free/img.jpg")
  .confirm-button-section
    .confirm-button(@click="goToMiniProgram") 免费领取
</template>

<script>
export default {
  name: 'Free',
  components: {
  },
  setup() {
    const goToMiniProgram = () => {
      const AppId = 'gh_15f28d818ed7'
      const miniPath = encodeURIComponent('pages/index/index?channel=app_trial_ad')
      const link = `ydwisdom://youdao.com/jumpWxMiniProgram?appid=${AppId}&path=${miniPath}`
      // 通知客户端跳转到微信小程序
      window.ydk.openDeeplink({
        link,
        success() {
        },
        fail() {
          window.ydk.toast({
            msg: '请升级至最新版',
          });
        },
      })
    }

    return {
      goToMiniProgram,
    }
  },
};
</script>

<style lang="stylus" scoped>
.free-page
  width 3.9rem
  margin 0 auto
  padding-bottom 1.24rem
.intro-img
  display block
  width 100%
.confirm-button-section
  position fixed
  bottom 0
  background #fff
  width 3.9rem
  box-sizing border-box
  z-index 100
  padding 0.2rem 0.24rem 0.54rem
</style>
